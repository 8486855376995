@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* --foreground-rgb: 0, 0, 0; */
  --background-start-rgb: 118, 192, 205;
  --background-end-rgb: 255, 255, 255;
  --font-wixmadefordisplay: "WixMadeforDisplay", sans-serif;
  --font-unbounded: "Unbounded", sans-serif;
  --background-color: #f6f6f6;
  --primary-color: rgb(255, 122, 28);
  --dark-color: #313131;
  --backdrop-color: rgba(49, 49, 49, 0.5);
  --gray-color: #a0a7b1;
  --light-gray-color: #737373;
}

@media (prefers-color-scheme: dark) {
  :root {
    /* --foreground-rgb: rgba(255, 255, 255); */
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html {
  touch-action: manipulation; /* 防止触摸滚动干扰，解决fixed无法固定在屏幕上的问题 */
}

body {
  font-size: 1rem;
  background: var(--background-color);
  /* @apply bg-gradient-to-b from-primary-light to-primary; */
  font-family: var(--font-wixmadefordisplay);
}
.bg-color {
  background-color: var(--background-color);
}

.color-primary {
  color: var(--primary-color) !important;
}

.color-gray {
  color: var(--gray-color) !important;
}

a {
  font-size: 0.8rem;
}

a:hover {
  @apply text-black;
}

/* 设置div背景图的默认显示方式 */
div {
  background-size: cover;
}

.center-center {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.left-center {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.right-center {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.col-top-left {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

/* 覆盖左右按钮的样式 */
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  background: rgba(0, 0, 0, 0.5); /* 按钮背景 */
  border-radius: 9999px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-carousel .slick-prev:after {
  position: relative !important;
  top: 0px;
  left: 1px;
}
.ant-carousel .slick-next:after {
  position: relative !important;
  top: 0px;
  left: -1px;
}

.offer-description .ant-descriptions-item-label {
  width: 160px; /* 设置标签列的宽度 */
}

.offer-description .ant-descriptions-item-content {
  width: 336px; /* 设置内容列的宽度 */
}

/* 自定义垂直滚动条 */
/* custom.css */
.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #fdb494 transparent; /* 自定义滚动条颜色 */
}

.thin-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: #fdb494; /* 滚动条颜色 */
  border-radius: 6px;
}

.thin-scrollbar::-webkit-scrollbar-track {
  background-color: transparent; /* 滚动条轨道颜色 */
}

.fade-in {
  opacity: 1;
}

.fade-out-o-07 {
  opacity: 0.7;
}

.fix-to-width {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fix-to-width-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

a {
  position: relative;
}

@keyframes gradient-move {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.a-nohover::before {
  height: 0px;
  width: 0px;
}
.a-nohover:hover::before {
  height: 0px;
  width: 0px;
}
.dropdown-menuitem-content-height {
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.topnav-text-link {
  font-family: var(--font-unbounded);
  font-size: 16px;
  font-weight: 300;
}

@media (max-width: 1919px) {
  .topnav-text-link {
    font-family: var(--font-unbounded);
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
  }
  .dropdown-menuitem-content-height {
    min-height: 44px;
  }
}
@media (max-width: 768px) {
  .topnav-text-link {
    font-family: var(--font-unbounded);
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
  }
  .dropdown-menuitem-content-height {
    min-height: 32px;
  }
}

.link-padding {
  padding: 12px;
}
.button-padding {
  padding: 12px 20px;
}
.button-menu-item-padding {
  padding-left: 8px;
}

.footer-nav-text-link {
  font-family: var(--font-unbounded);
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
}

.transparent-bg {
  background-color: transparent;
  border-color: transparent;
}

/* 社媒按钮 */
.social-media-button {
  padding: 0;
  border: none;
  background: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.social-media-button:hover {
  background: black;
}

.social-media-button:disabled .social-media-icon path,
.social-media-button:disabled:hover .social-media-icon path {
  fill: #f0f0f0;
}
.social-media-button:disabled .social-media-icon rect,
.social-media-button:disabled:hover .social-media-icon rect {
  fill: #e0e0e0;
}

.social-media-icon path,
.social-media-icon rect {
  transition: fill 0.3s ease;
}

.social-media-button:hover .social-media-icon path {
  fill: white;
}

.social-media-button:hover .social-media-icon rect {
  fill: black;
}

/* chatbo按钮风格 */
#livechat_launcher_btn > div:first-child {
  background: black !important;
  border-radius: 0px !important;
}

#livechat_launcher_btn .foldup_svg__icon > path {
  fill: black !important;
}

.rotate180 {
  transform: rotate(180deg);
}

.text-title {
  font-size: 90px;
  font-weight: 600;
  font-family: var(--font-unbounded);
  line-height: 112px;
  text-transform: uppercase;
}
.arrow-button {
  width: 52px;
  height: 52px;
}
@media (max-width: 1919px) {
  .text-title {
    font-size: 72px;
    line-height: 90px;
  }
  .arrow-button {
    width: 52px;
    height: 52px;
  }
  .link-padding {
    padding: 8px;
  }
  .button-padding {
    padding: 20px 8px 6px 8px;
  }
}
@media (max-width: 1365px) {
  .text-title {
    font-size: 58px;
    line-height: 80px;
  }
  .arrow-button {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1023px) {
  .text-title {
    font-size: 42px;
    line-height: 52px;
  }
  .footer-nav-text-link {
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
  }
}
@media (max-width: 768px) {
  .text-title {
    font-size: 22px;
    line-height: 28px;
  }
  .arrow-img {
    width: 9px;
    height: 9px;
  }
}

/* 设置antd组件的默认样式为无背景色/无hover和focus效果 */
.transparent-style {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.transparent-style:hover,
.transparent-style:focus {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

/* flexbox 元素多行文本显示省略号 */
.flex-child-clamp-1 {
  display: -webkit-box; /* 必须启用 flex 容器 */
  -webkit-line-clamp: 1; /* 限制显示的行数为 2 */
  -webkit-box-orient: vertical; /* 设置布局方向为垂直 */
  overflow: hidden; /* 超出内容隐藏 */
  word-break: break-all;
}
.flex-child-clamp-2 {
  display: -webkit-box; /* 必须启用 flex 容器 */
  -webkit-line-clamp: 2; /* 限制显示的行数为 2 */
  -webkit-box-orient: vertical; /* 设置布局方向为垂直 */
  overflow: hidden; /* 超出内容隐藏 */
  word-break: break-all;
}
.flex-child-clamp-3 {
  display: -webkit-box; /* 必须启用 flex 容器 */
  -webkit-line-clamp: 2; /* 限制显示的行数为 2 */
  -webkit-box-orient: vertical; /* 设置布局方向为垂直 */
  overflow: hidden; /* 超出内容隐藏 */
  word-break: break-all;
}
.flex-child-clamp-4 {
  display: -webkit-box; /* 必须启用 flex 容器 */
  -webkit-line-clamp: 2; /* 限制显示的行数为 2 */
  -webkit-box-orient: vertical; /* 设置布局方向为垂直 */
  overflow: hidden; /* 超出内容隐藏 */
  word-break: break-all;
}

/* 左右翻页按钮enable状态下的hover样式 */
.arrow-button:enabled:hover svg .arr-ow path {
  fill: white !important;
}
.arrow-button:enabled:hover svg rect {
  fill: black;
}

.filter-action-button {
  background-color: white;
  color: var(--dark-color);
  border: 0;
  padding: 8px;
  min-width: 44px;
  min-height: 44px;
}

.filter-action-button:hover {
  background-color: white;
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .filter-action-button {
    min-width: 32px;
    min-height: 32px;
  }
}
